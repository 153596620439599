import React from 'react'

function TopNav() {
  return (
    <>
    <div className='home-page' style={{backgroundColor:"#004877"}}>
    <div className="topbar" style={{backgroundColor:"#46bd55"}}>
<div className="container">
  <div className="row">
    <div className="col-md-12">
      <p className="pull-left hidden-xs"><i className="fa fa-clock-o">
        </i><span>Mon - Fri 9:00 AM - 4:00PM. Sunday,Saturday CLOSED</span> </p>
      <p className="pull-right"><i className="fa fa-envelope"></i>Email. edurecruitpk@gmail.com &nbsp;&nbsp; &nbsp; &nbsp;       <i className="fa fa-phone"></i>UAN No. (0311) 1007748</p>
    </div>
  </div>
</div>
</div></div>
</>
  )
}

export default TopNav