import React from 'react'
import {Table } from 'react-bootstrap'
import Navbar from '../Components/Layout/Navbar'
import TopNav from '../Components/Layout/TopNav'
import { Link } from 'react-router-dom'
import Footer from '../Components/Layout/Footer'

function Forms() {
  return (
    <>
      <TopNav />
    <Navbar />
    <div className='container'>
        
    <Table  bordered variant="dark">
      <thead>
        <tr style={{backgroundColor:"green",color:"white",textAlign:"center"}}>
          
          <th colSpan={2} style={{textAlign:"center",fontSize:"14pt"}}>Schools Education Department Balochistan Recruitment Project 2023 (Teaching-BPS-09 To BPS-15)</th>
          
        </tr>
      </thead>
      <tbody style={{backgroundColor:"#377840",color:"white"}}>
     
      <tr>
          
          <td>Elementary School Teacher (EST)(BPS-15)</td>
          <td><a href="assets/form/FormB(EST).pdf" target="_blank" style={{color:"white"}}>Click Here</a></td>
        </tr>
        <tr>
          
          <td>Junior Arabic Teacher (JAT)(BPS-14)</td>
          <td><a href="assets/form/FormG(JAT).pdf" target="_blank" style={{color:"white"}}>Click Here</a></td>
        </tr>
        <tr>
          
          <td>Junior Drawing Master (JDM)(BPS-14)</td>
          <td><a href="assets/form/FormE(JDM).pdf" target="_blank" style={{color:"white"}}>Click Here</a></td>
        </tr>

        <tr>
          
          <td>Junior English Teacher (JET)(BPS-14)</td>
          <td><a href="assets/form/FormC(JET).pdf" target="_blank" style={{color:"white"}}>Click Here</a></td>
        </tr>

        <tr>
          
          <td>Junior English Teacher Tech(JET(T))(BPS-14)</td>
          <td><a href="assets/form/FormH(JET(T).pdf" target="_blank" style={{color:"white"}}>Click Here</a></td>
        </tr>

       
        


        
        
        
        <tr>
          
          <td>Physical Education Teacher (PET)(BPS-14)</td>
          <td><a href="assets/form/FormF(PET).pdf" target="_blank" style={{color:"white"}}>Click Here</a></td>
        </tr>
        
        <tr>
          
          <td>Junior Vernacular Teacher (JVT)(BPS-09)</td>
          <td><a href="assets/form/FormA(JVT).pdf" target="_blank" style={{color:"white"}}>Click Here</a></td>
        </tr>
       
        <tr>
          
          <td>Mualim ul Quran (MQ)(BPS-09)</td>
          <td><a href="assets/form/FormD(MQ).pdf" target="_blank" style={{color:"white"}}>Click Here</a></td>
        </tr>
        
      </tbody>
    </Table>
    </div>
    <Footer />
     </>
  )
}

export default Forms