import React from 'react'

function Footer() {
  return (
    <>
		
	<div id="sub-footer">
		<div className="container">
			<div className="row">
				<div className="col-sm-6">
					<div className="copyright">
                    <p style={{fontFamily:"Arial, Helvetica, sans-serif",color:"black",textAlign:"justify"}}>
							<span>&copy;  2023 All right reserved. Developed By </span><a href="https://edurecruit.com.pk/" target="_blank">IT Team</a>
						</p>
					</div>
				</div>
				<div className="col-sm-6">
					<ul className="social-network">
						<li><a href="#" data-placement="top" title="Facebook"><i className="fa fa-facebook"></i></a></li>
						<li><a href="https://www.youtube.com/@Edurecruit2023" data-placement="top" title="Twitter"><i className="fa fa-youtube"></i></a></li>
						<li><a href="#" data-placement="top" title="Linkedin"><i className="fa fa-linkedin"></i></a></li>
						<li><a href="#" data-placement="top" title="Pinterest"><i className="fa fa-pinterest"></i></a></li>
						<li><a href="#" data-placement="top" title="Google plus"><i className="fa fa-google-plus"></i></a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
    </>
  )
}

export default Footer