import { Route, Routes } from 'react-router-dom';
import './App.css';
import About from './Pages/About';
import Contact from './Pages/Contact';
import HomePage from './Pages/HomePage';
import ProjectDetail from './Pages/ProjectDetail';
//import VacancyStatement from './Pages/VacancyStatement';
import Forms from './Pages/Forms';
import AcceptRejectList from './Pages/AcceptRejectList/AcceptRejectList';
//import RollSlip from './Pages/RollNoSlip/RollSlip';
//import ResultList from './Pages/ResultList/ResultList';
import DevAcceptRejectList from './Pages/AcceptRejectList/DevAcceptRejectList';
import Answerkesy from './Pages/Answerkeys';
import Rakhshan from './Pages/Answerkeys/Rakhshan';
import Zhob from './Pages/Answerkeys/Zhob';
import Loralai from './Pages/Answerkeys/Loralai';
import KalatI from './Pages/Answerkeys/KalatI';
import KalatII from './Pages/Answerkeys/KalatII';
import Makran from './Pages/Answerkeys/Makran';
import QuettaI from './Pages/Answerkeys/quettaI';
import NasirabadI from './Pages/Answerkeys/NasirabadI';
import NasirabadII from './Pages/Answerkeys/NasirabadII';
import Sibi from './Pages/Answerkeys/Sibi';
import QuettaII from './Pages/Answerkeys/QuettaII';
import Basima from './Pages/Answerkeys/Basima';
import MeritList from './Pages/ResultList/MeritList';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/projects' element={<ProjectDetail />} />
        
        <Route path='/Form' element={<Forms />} />
        <Route path='/Listing' element={<AcceptRejectList />} />
        <Route path='/RollNo' element={<DevAcceptRejectList />} />
        
        
        <Route path='/AnswerKeys' element={<Answerkesy />} />
        <Route path='/Rakhshan' element={<Rakhshan />} />
        <Route path='/Zhob' element={<Zhob />} />
        <Route path='/Loralai' element={<Loralai />} />
        <Route path='/KalatI' element={<KalatI />} />
        <Route path='/KalatII' element={<KalatII />} />
        <Route path='/Makran' element={<Makran />} />
        <Route path='/QuettaI' element={<QuettaI />} />
        <Route path='/NaseerabadI' element={<NasirabadI />} />
        <Route path='/NaseerabadII' element={<NasirabadII />} />
        <Route path='/Sibi' element={<Sibi />} />
        <Route path='/QuettaII' element={<QuettaII />} />
        <Route path='/Basima' element={<Basima />} />
        <Route path='/MeritList' element={<MeritList />}/>
      </Routes>

    </div>
  );
}

export default App;
