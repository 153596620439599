import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import TopNav from "../../Components/Layout/TopNav";
import Navbar from "../../Components/Layout/Navbar";
import Footer from "../../Components/Layout/Footer";
import axios from "axios";
import { API_URL } from "../../config";
import { Link } from "react-router-dom";
function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 2000));
}
function UsernameInput({ onSubmit }) {
  const [username, setUsername] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const endpoint = "/SPR_RollSlipAsync";
  useEffect(() => {}, [username]);
  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setisLoading(false);
      });
    }
  }, [isLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username || isNaN(username)) {
      alert("Please enter a valid CNIC (only numbers are allowed)");
      setUsername("");
      onSubmit(null);
      return;
    }

    if (username.length > 15) {
      alert("CNIC cannot be longer than 15 digits");
      setUsername("");
      onSubmit(null);
      return;
    }
    setisLoading(true);
    let params = {
      WhereID: username,
    };
    try {
      const response = await axios.get(`${API_URL}${endpoint}`, { params });
      if (response.data.length === 0) {
        alert("No record found for the given CNIC");
        setUsername("");
        onSubmit(null);
      } else {
        onSubmit(response.data);
      }
    } catch (error) {
      alert("Invalid CNIC entered");
      onSubmit(null);
      setUsername("");
    }
    setisLoading(false);
  };

  return (
    <>
      <form>
        <Table variant="dark">
          <tbody>
            <tr>
              <td>
                <label style={{ textAlign: "center", fontSize: "21pt" }}>
                  Enter Your CNIC (Without Dashes): &nbsp;
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </label>
              </td>
              <td>
                <Button
                  variant="primary"
                  disabled={isLoading}
                  onClick={!isLoading ? handleSubmit : null}
                >
                  {isLoading ? "Loading…" : "See List"}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </form>
    </>
  );
}
function ResultTable({ data }) {
  const loadRollNoSlip = async (id) => {
    const patterns = {
      0: "shP",
      1: "myc",
      2: "peQ",
      3: "SvO",
      4: "wqy",
      5: "LEU",
      6: "wmt",
      7: "tnk",
      8: "Qsd",
      9: "rvg",
    };
    let encrypted = "";
    const idString = id.toString();
    for (let i = 0; i < idString.length; i++) {
      encrypted += patterns[idString.charAt(i)];
    }
    const url = `/RollNo/${encrypted}`;
    window.open(url, "_blank");
  };
  return (
    <>
      <div className="container">
        <Table variant="dark">
          <thead>
            <tr
              style={{
                backgroundColor: "green",
                color: "white",
                textAlign: "center",
              }}
            >
              <th
                colSpan={10}
                style={{ textAlign: "center", fontSize: "14pt" }}
              >
                Schools Education Department Balochistan Recruitment Project
                2023 (Teaching-BPS-09 To BPS-15)
              </th>
            </tr>
          </thead>

          <br />
          <thead>
            <tr>
              <th
                colSpan={3}
                rowSpan={3}
                style={{
                  textAlign: "center",
                }}
              >
                <img className='profile' src={`https://registration.edurecruit.com.pk/UploadImages/Picture/${data[0].pic}`} alt="Profile" />
              </th>
              <th style={{ backgroundColor: "#377840", color: "white" }}>
                Name
              </th>
              <th colSpan={2}>{data[0].fullName}</th>
            </tr>

            <tr>
              <th style={{ backgroundColor: "#377840", color: "white" }}>
                So/Do/Wo
              </th>
              <th colSpan={2}>{data[0].fhName}</th>
            </tr>
          </thead>
          <br />

          <tbody>
            <tr style={{ backgroundColor: "#377840", color: "white" }}>
              <td>Post</td>
              <td>District</td>
              <td>Tehsil</td>
              <td>Union Council</td>
              <td>Academic Marks</td>
              <td>Professional marks</td>
              <td>STATUS</td>
              <td>Roll No Slip</td>
            </tr>
            {data.map((row) => (
              <tr key={row.userID + row.postsTitle}>
                <td>{row.postsTitle}</td>
                <td>{row.district}</td>
                <td>{row.tehsil}</td>
                <td>{row.ucName}</td>
                <td>{row.acadMarks}</td>
                <td>{row.profMarks}</td>
                <td>{row.jobStatusid}</td>
                <td>
                  <Button
                    onClick={() => {
                      loadRollNoSlip(row.userJID);
                    }}
                  >
                    See Roll No. Slip
                  </Button>
                </td>
              </tr>
            ))}
            <tr
              style={{
                backgroundColor: "white",
                color: "red",
                textAlign: "center",
              }}
            >
              <th
                colSpan={11}
                style={{ textAlign: "center", fontSize: "14pt" }}
              >
                Note: If the candidates have any issue regarding their
                marks/status/any other query, they shall login to their profile
                on our portal : edurecruit.com.pk to register online complaint
                with in stipulated Time i.e. 5 days{" "}
              </th>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default function DevAcceptRejectList() {
  const [result, setResult] = useState(null);

  const handleQueryResult = (data) => {
    setResult(data);
  };

  return (
    <>
      <TopNav />
      <Navbar />
      <div className="container">
        <UsernameInput onSubmit={handleQueryResult} />
        {result && <ResultTable data={result} />}
      </div>
      {result <= 0 ? (
        <>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      ) : (
        <></>
      )}

      <Footer />
    </>
  );
}
