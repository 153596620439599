import React from 'react'
import Footer from '../Components/Layout/Footer'
import Navbar from '../Components/Layout/Navbar'
import TopNav from '../Components/Layout/TopNav'

function Contact() {
  return (
    <>
      <TopNav />
  <Navbar />
  <h3>Contact Us</h3>
  <div className='container'>
<h4><i className="fa fa-phone"></i>UAN. 03111007748 </h4>
<h4><i className="fa fa-envelope"></i>Email. edurecruitpk@gmail.com </h4>
 
  </div>
  <Footer />
    </>
  )
}

export default Contact