import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import News from './News';
import { Link } from 'react-router-dom';


function MainSlider() {
  return (
    <>
    <div className='container'>
    <div className='row'>
        <div className='col-md-8'>
    <Carousel showArrows={true} showThumbs={false}
    autoPlay={true} infiniteLoop={true} interval={6000}
    transitionTime={50}>
                <div>
                    <img src="sbk1.jpg" style={{width:"100%",height:"300px"}} />
                    
                </div>
                <div>
                    <img src="sbk2.jpg" style={{width:"100%",height:"300px"}}/>
                    </div>
                <div>
                    <img src="sbk3.jpg" style={{width:"100%",height:"300px"}}/>
                    </div>
                <div>
                    <img src="sbk4.jpg" style={{width:"100%",height:"300px"}}/>
                   </div>
                <div>
                    <img src="sbk4.jpg" style={{width:"100%",height:"300px"}}/>
                    
                </div>
                
                
            </Carousel>
            </div>
            <div className='col-md-4' style={{height:"400px"}}>
            
               <h3 style={{backgroundColor:"#46bd55",color:"#ffff",marginTop:"-5px",padding:"9px"}}>Important Announcement</h3>
                
               
           
               
                   <h4 style={{textAlign:"left"}}><Link to='projects'>Project Detail</Link></h4>

                   <h4 style={{textAlign:"left"}}>
                    <img src="ClickHere.gif" style={{width:"10%",height:"30px"}}/>
                    <a href="assets/PressRelease/ResultPressRelease.pdf" target="_blank"><span style={{color:"blue"}}>Press Release</span></a>
                     </h4>
                     <h4 style={{textAlign:"left"}}>
                     <img src="ClickHere.gif" style={{width:"10%",height:"30px"}}/>
                        <Link to='MeritList'><span style={{color:"green"}}>Tentative Merit List</span></Link></h4>
                   
                        
                    
                  
                  
            </div>
            </div>
            </div>
    </>
  )
}

export default MainSlider