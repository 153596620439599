import React from 'react'
import { Link } from 'react-router-dom'

function Navbar() {
  return (
    <>
     <header>
        <div className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <a className="navbar-brand" style={{marginTop:"1px"}}>
                        <img src={'assets/img/sbklogo.jpg'} alt="logo" style={{height:"50px",marginTop:"5px"}} />
                        </a>
                </div>
                <div className="navbar-collapse collapse ">
                    <ul className="nav navbar-nav">
                        <li className="active"><Link to='/'>Home</Link></li> 
                        <li><Link to='/projects'>Projects</Link></li>
                        <li className="active"><Link to='/'>Services</Link></li>
                        
                        <li><Link to='/'>Test Center</Link></li>
                        <li><Link to='/'>Invigilation Staff</Link></li>
            
                        <li><Link to='/contact'>Contact us</Link></li>
                    

                        <li><a href="https://registration.edurecruit.com.pk/" target="_blank">Login/Register</a></li>
                    </ul>
                </div>
            </div>
        </div>
        </header>
        <hr style={{height:"1px", border:"1px solid #46bd55",marginTop:"-1px"}}/>
	
    
    </>
  )
}

export default Navbar