import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Table } from "react-bootstrap";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";

import TopNav from "../../Components/Layout/TopNav";
import Navbar from "../../Components/Layout/Navbar";
import Footer from "../../Components/Layout/Footer";
import axios from "axios";
import { API_URL } from "../../config";
import ResultToPrint from "./ResultToPrint";
import { Link } from "react-router-dom";
function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 2000));
}
function UsernameInput({ onSubmit }) {
  const [username, setUsername] = useState("");
  const [rollNumber, setRollNumber] = useState("");
  const [userInput, setUserInput] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const endpoint = "/SPR_ResultListAsync";
  useEffect(() => { }, [username]);
  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setisLoading(false);
      });
    }
  }, [isLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateCaptcha(userInput) === false) {
      alert("Captcha value does not match. Please try again.");
      setUserInput("");
      return;
    }
    if (!username || isNaN(username)) {
      alert("Please enter a valid CNIC (only numbers are allowed)");
      setUsername("");
      onSubmit(null);
      return;
    }
    if (!rollNumber || isNaN(rollNumber)) {
      alert("Please enter a valid Roll Number (only numbers are allowed)");
      setRollNumber("");
      onSubmit(null);
      return;
    }
    if (username.length > 15) {
      alert("CNIC cannot be longer than 15 digits");
      setUsername("");
      onSubmit(null);
      return;
    }
    setisLoading(true);
    let params = {
      CNIC: username,
      RollNo: rollNumber,
    };
    try {
      const response = await axios.get(`${API_URL}${endpoint}`, { params });
      if (response.data.length === 0) {
        alert("Your  Result is Under Process");
        setUsername("");
        setRollNumber("");
        setUserInput("");
        onSubmit(null);
      } else {
        onSubmit(response.data);
      }
    } catch (error) {
      alert("Invalid CNIC or Roll Number entered");
      onSubmit(null);
      setUsername("");
      setRollNumber("");
      setUserInput("");
    } finally {
      setisLoading(false);
    }
  };
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);
  return (
    <>
    <br/>
      <form style={{ maxWidth: "600px", margin: "0 auto" }}>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <label
            style={{ fontSize: "18px", marginRight: "10px", width: "200px" }}
          >
            Enter Your CNIC (Without Dashes):
          </label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{ flex: "1", padding: "8px", fontSize: "16px" }}
          />
        </div>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <label
            style={{ fontSize: "18px", marginRight: "10px", width: "200px" }}
          >
            Enter Your Roll Number:
          </label>
          <input
            type="text"
            value={rollNumber}
            onChange={(e) => setRollNumber(e.target.value)}
            style={{ flex: "1", padding: "8px", fontSize: "16px" }}
          />
        </div>
        <div style={{ marginBottom: "20px", textAlign: "center" }}>
          <div style={{ display: "inline-block" }}>
            <LoadCanvasTemplate reloadButtonStyle={{}} />
          </div>
        </div>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Enter the Captcha Value"
            style={{ width: "200px", padding: "8px", fontSize: "16px" }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            type="submit"
            disabled={isLoading}
            onClick={handleSubmit}
            style={{
              padding: "8px 16px",
              fontSize: "16px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            {isLoading ? "Loading..." : "View Result"}
          </button>
        </div>
      </form>
    </>
  );
}
function ResultTable({ data }) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const loadRollNoSlip = async (id) => {
  
    const patterns = {
      0: "shP",
      1: "myc",
      2: "peQ",
      3: "SvO",
      4: "wqy",
      5: "LEU",
      6: "wmt",
      7: "tnk",
      8: "Qsd",
      9: "rvg",
    };
    let encrypted = "";
    const idString = id.toString();
    for (let i = 0; i < idString.length; i++) {
      encrypted += patterns[idString.charAt(i)];
    }
   
    window.open(`https://result.edurecruit.com.pk/ViewMarksdata.aspx?en=${encrypted}`, "_blank");
  };
  const loadlist = async (id) => {
  
    const patterns = {
      0: "shP",
      1: "myc",
      2: "peQ",
      3: "SvO",
      4: "wqy",
      5: "LEU",
      6: "wmt",
      7: "tnk",
      8: "Qsd",
      9: "rvg",
    };
    let encrypted = "";
    const idString = id.toString();
    for (let i = 0; i < idString.length; i++) {
      encrypted += patterns[idString.charAt(i)];
    }
   
    window.open(`https://result.edurecruit.com.pk/result.aspx?en=${encrypted}`, "_blank");
  };
  return (
    <>
      <div className="container">
        <Table variant="dark" style={{ border: "none" }}>
          <thead>
            <tr
              style={{
                backgroundColor: "green",
                color: "white",
                textAlign: "center",
              }}
            >
              <th
                colSpan={10}
                style={{ textAlign: "center", fontSize: "14pt" }}
              >
                Schools Education Department Balochistan Recruitment Project
                2023 (Teaching-BPS-09 To BPS-15)
              </th>
            </tr>
          </thead>
          <br />
          <thead>
            <tr>
              <th
                colSpan={3}
                rowSpan={3}
                style={{
                  textAlign: "center",
                }}
              >
                <img
                  className="profile"
                  src={`https://result.edurecruit.com.pk/UploadImages/Picture/${data[0].pic}`}
                  alt="Profile"
                />
              </th>
              <th
                style={{
                  backgroundColor: "#377840",
                  color: "white",
                }}
              >
                Name
              </th>
              <th colSpan={2}>{data[0].fullName}</th>
              <th
                style={{
                  backgroundColor: "#377840",
                  color: "white",
                }}
              >
                CNIC
              </th>
              <th colSpan={2}>{data[0].userName}</th>
            </tr>
            <tr>
              <th style={{ backgroundColor: "#377840", color: "white" }}>
                So/Do/Wo
              </th>
              <th colSpan={2}>{data[0].fhName}</th>
              <th style={{ backgroundColor: "#377840", color: "white" }}>
                Roll Number
              </th>
              <th colSpan={2}>{data[0].rollNo}</th>
            </tr>
          </thead>
          <br />
          <tbody>
            <tr
              style={{
                backgroundColor: "#377840",
                color: "white",
              }}
            >
              <td>Post</td>
              <td>District</td>
              <td>Tehsil</td>
              <td>Union Council</td>
              
              <td>View Tentative Merit List</td>
              <td>Query</td>
            </tr>
            {data.map((row) => (
              <tr key={row.userID + row.postsTitle}>
                <td>{row.postsTitle}</td>
                <td>{row.district}</td>
                <td>{row.tehsil ? row.tehsil : "APPLIED ON DISTRICT"}</td>
                <td>
                  {row.ucName
                    ? row.ucName
                    : row.tehsil
                      ? "APPLIED ON TEHSIL"
                      : "APPLIED ON DISTRICT"}
                </td>
                
                <td>
                  <>
                  <Button style={{
                    backgroundColor: "red",
                    color: "white"
                  }} onClick={() => loadlist(row?.userJID)}>
                    View Tentative Merit List
                  </Button>
                    <div style={{ display: "none" }}>
                      <ResultToPrint ref={componentRef} data={data} />
                    </div>
                  </>
                </td>
                <td>

                  <Button style={{
                    backgroundColor: "red",
                    color: "white"
                  }} onClick={() => loadRollNoSlip(row?.userJID)}>
                    Submit Query
                  </Button>
                </td>
              </tr>
            ))}
            <tr
              style={{
                backgroundColor: "white",
                color: "red",
                textAlign: "center",
              }}
            >
              <th
                colSpan={11}
                style={{ textAlign: "center", fontSize: "14pt" }}
              >
                Note: If the candidates have any issue regarding Tentative Merit List,  Register online complaint
                with in stipulated Time i.e. 3 Working days{" "}
              </th>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}
export default function ResultList() {
  const [result, setResult] = useState(null);
  const handleQueryResult = (data) => {
    setResult(data);
  };
  return (
    <>
      <TopNav />
      <Navbar />
      <div className="container">
        {!result && <UsernameInput onSubmit={handleQueryResult} />}
        {result && <ResultTable data={result} />}
      </div>
      {result <= 0 ? (
        <>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      ) : (
        <></>
      )}
      <Footer />
    </>
  );
}
