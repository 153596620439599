import React from 'react'
import TopNav from '../Components/Layout/TopNav'
import '../App.css';
import AboutSection from '../Components/Layout/AboutSection';
import Feature from '../Components/Layout/Feature';
import Footer from '../Components/Layout/Footer';
import MainSlider from '../Components/Layout/MainSlider';
import Navbar from '../Components/Layout/Navbar';
import News from '../Components/Layout/News';

function HomePage() {
  return (
    <>
    <TopNav />
    <Navbar />
      <MainSlider />
      <Feature />
      <News />
      <AboutSection />
      <Footer />
    
    </>
  )
}

export default HomePage