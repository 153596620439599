import React from 'react'
import { Link } from 'react-router-dom'

function Feature() {
  return (
    <>
    <section id="content" style={{marginBottom:"-80px"}}>
	<div className="container"> 
			<div className="row">
		<div className="skill-home"> <div className="skill-home-solid clearfix"> 
		<Link to='/projects'>
        <div className="col-md-3 col-sm-6 text-center">
		<span className="icons c1"><i className="fa fa-bullhorn"></i></span> <div className="box-area">
		<h3>New Projects</h3> </div>
		</div>
        </Link>
        <a  href="https://registration.edurecruit.com.pk/" target="_blank">
		<div className="col-md-3 col-sm-6 text-center"> 
		<span className="icons c2"><i className="fa fa-user"></i></span> <div className="box-area">
		<h3>Online Apply</h3> </div>
		</div>
        </a>
		<div className="col-md-3 col-sm-6 text-center"> 
		<span className="icons c3"><i className="fa fa-star"></i></span> <div className="box-area">
		<h3>Roll Number</h3> </div>
		</div>
		<Link to='/Result'>
		<div className="col-md-3 col-sm-6 text-center"> 
		<span className="icons c4"><i className="fa fa-trophy"></i></span> <div className="box-area">
		<h3>Result</h3></div></div></Link>
		</div></div>
		</div> 
	  
	</div>
	
	</section>

    
		
    </>
  )
}

export default Feature