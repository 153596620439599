import React from 'react'
import Navbar from '../Components/Layout/Navbar'


function About() {
  return (
    <div>
        <Navbar />
        <h3>About Us Page</h3>
    </div>
  )
}

export default About