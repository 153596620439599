import React from 'react'
import Marquee from 'react-fast-marquee'
import { Link } from 'react-router-dom'

function News() {
  return (
    <>
    <div className='container'>
    
    <Marquee pauseOnHover={true} direction={"left"} 
     gradient={false} gradientWidth="500px" style={{backgroundColor:"#46bd55",color:"#ffff",padding:""}}>
     <img src="ClickHere.gif" style={{width:"30px",height:"30px",marginRight:"5px"}}/>
    <h4 style={{backgroundColor:"#46bd55",color:"#000000",height:"7px"}}><Link to='/MeritList' style={{color:"red"}}>Tentative General Merit List<span style={{color:"blue"}}> Click Here &nbsp;&nbsp;&nbsp;&nbsp;</span></Link> </h4>
    </Marquee>
  
    </div>
    </>
  )
}

export default News