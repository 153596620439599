import React from 'react'

function AboutSection() {
  return (
    <>
    <div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="section-title text-center">
						<h2 style={{backgroundColor:"#46bd55",color:"#ffff",padding:"10px"}}>About University</h2>
						<p style={{fontFamily:"Arial, Helvetica, sans-serif",color:"black",textAlign:"justify"}}>
                        Balochistan has the privilege to house its first Women’s University at Quetta. The need for a Women’s University in Balochistan was felt from many decades, this need was finally realized when the Governor of Balochistan, Mr. Owais Ahmed Ghani , promulgated an Ordinance No. 1 of 2004 to establish the Sardar Bahadur Khan Women’s University at Quetta. On 26th August 2004, Balochistan Provincial Assembly passed The Sardar Bahadur Khan Women’s University Act. The University has been established in Sardar Bahadur Khan Santorium very generously donated by the Pakistan Railways without any compensation. The premises of the University spread over 40 acres. Keys of the building were handed over to the Vice Chancellor on 18 March 2004, the building was witness to a historic transformation where it was given the status of Balochistan's first and Pakistan's third Women's University. From that day onwards, the Sardar Bahadur Khan Women's University of Balochistan has been striving to provide a safe haven to the women of Balochistan and Pakistan who are interested in educational and intellectual pursuits.

<br/>Masters level classes began in a record period of 59 days with an initial enrollment of 103 students in 9 disciplines. Currently, young women coming from all over Balochistan are studying in 27 disciplines. Quality education in English is being imparted following the semester system. 15th session at the university is in progress. The university has a total enrollment of over 9000 students and it is increasing in every new session.
                           
                           
                           </p>
					</div>
				</div>
			</div>


			<div className="row">
            <div className="col-md-4">
               <h3 style={{backgroundColor:"#46bd55",color:"#ffff",padding:"5px"}}>Philosophy</h3>
                <p style={{fontFamily:"Arial, Helvetica, sans-serif",color:"black",textAlign:"justify"}}>
                Sardar Bahadur Khan Women’s University aims to provide the women of Balochistan and the rest of Pakistan with an excellent standard of education and with the best skills suited to which ever station they choose in their lives regardless of creed, class, age or domicile. SBK Women’s University aspires to impart education that not only develops the mind of its students. It also seeks to make its student socially responsible citizens. The University sincerely believes that revolution begins from within the home and by educating women, it hopes to make the future of Balochistan and Pakistan brighter.
                </p></div>

            <div className="col-md-4">
               <h3 style={{backgroundColor:"#46bd55",color:"#ffff",padding:"5px"}}> Vision and Mission</h3>
               <p style={{fontFamily:"Arial, Helvetica, sans-serif",color:"black",textAlign:"justify"}}>
               The Vision is that SBKWU is striving for academic excellence with International compatibility by providing quality teaching and research.

<br/>The mission is to prepare female leaders from the local community, possessing a grasp for complexity, effective problem solving and communication skills and an enduring commitment to learning and ethical conduct in order to adapt to the challenges of the changing world. As a result of this, produce inspiring female scholars that can effect positive social change at local, national and global levels.


                
                </p></div>

            <div className="col-md-4">
               <h3 style={{backgroundColor:"#46bd55",color:"#ffff",padding:"5px"}}>Goals</h3>
               <p style={{fontFamily:"Arial, Helvetica, sans-serif",color:"black",textAlign:"justify"}}>
               To develop inspiring women professionals who can be a role model for the females or the area and develop linkages with the external world.
<br/>To educate women and strengthen their leadership qualities so that they offer quality service based on the ethical value system within their communities.
<br/>To provide opportunities of research and development in areas of vital concern to the women of Balochistan.
<br/>To provide opportunities of research and development in areas of vital concern to the women of Balochistan.


                
                </p></div>
             
			    
        </div>
		</div>
	
    </>
  )
}

export default AboutSection