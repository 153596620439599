import React from "react";
import QRCode from "qrcode.react";
const ResultToPrint = React.forwardRef(({ data }, ref) => {
  const qrValue = `${data[0].rollNo}`;
  const obtMarksPercentage = (data[0].obtained / 70) * 100;
  var rPrintDate = new Date(),
    resultDate =
      rPrintDate.getDate() +
      "/" +
      (rPrintDate.getMonth() + 1) +
      "/" +
      rPrintDate.getFullYear() +
      " " +
      rPrintDate.getHours() +
      ":" +
      rPrintDate.getMinutes() +
      ":" +
      rPrintDate.getMilliseconds();
  return (
    <>
      <style>
        {`
        table,td,th {
          border:1px solid black;
          padding-left: 5px;
          border-collapse: collapse;
        }
        .toCentreTD{
          text-align: center;
          padding:1px;
        }
        .printed-date {
          bottom: 0;
          left: 0;
          right: 0;
          text-align: center;
          font-size: 12px;
          padding: 10px;
          }
        `}
      </style>
      <div style={{ display: "flex", justifyContent: "center" }} ref={ref}>
        <div style={{ width: "750px", fontSize: "13.5px", padding: "20px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ width: "15%" }}>
              <img
                src={"/assets/img/sbklogo.jpg"}
                alt="logo"
                style={{ height: "90px", marginTop: "5px" }}
              />
            </div>
            <div style={{ textAlign: "center", width: "80%" }}>
              <h3>Sardar Bahadur Khan Women’s University </h3>
              <h4>Result</h4>
              <h4>
                (Recruitment Project of Schools Education Department Balochistan
                2023)
              </h4>
            </div>
            <div>
              <QRCode size={90} value={qrValue} />
            </div>
          </div>
          <hr />
          <h2 style={{ textAlign: "center" }}>
            <u>Post - {data[0].postsTitle}</u>
          </h2>
          <br />
          <div style={{ display: "flex" }}>
            <table
              style={{ width: "100%", fontSize: "17px", textAlign: "left" }}
            >
              <tr>
                <th style={{ width: "20%" }}>ROLL NUMBER</th>
                <th>{data[0].rollNo}</th>
                <th rowSpan={5} style={{ width: "18%" }}>
                  <img
                    src={`https://result.edurecruit.com.pk/UploadImages/Picture/${data[0].pic}`}
                    alt="Profile"
                    style={{
                      height: "130px",
                      width: "130px",
                      margin: "2px auto",
                    }}
                  />
                </th>
              </tr>
              <tr>
                <th>NAME</th>
                <th>{data[0].fullName}</th>
              </tr>
              <tr>
                <th>W/S/D of</th>
                <th>{data[0].fhName}</th>
              </tr>
              <tr>
                <th>CNIC No</th>
                <th>{data[0].userName}</th>
              </tr>
            </table>
          </div>
          <br />
          <div>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="toCentreTD">DISTRICT</th>
                  <th className="toCentreTD">TEHSIL</th>
                  <th className="toCentreTD">UC</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="toCentreTD">{data[0].district}</td>
                  <td className="toCentreTD">
                    {data[0].tehsil ? data[0].tehsil : "APPLIED ON DISTRICT"}
                  </td>
                  <td className="toCentreTD">
                    {data[0].ucName
                      ? data[0].ucName
                      : data[0].tehsil
                        ? "APPLIED ON TEHSIL"
                        : "APPLIED ON DISTRICT"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="toCentreTD">Test Total Marks</th>
                  <th className="toCentreTD">Wrriten Test Marks</th>
                  <th className="toCentreTD">Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="toCentreTD">100</td>
                  <td className="toCentreTD">{data[0].actualMarks}</td>
                  <td className="toCentreTD">
                    {obtMarksPercentage.toFixed(2)}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="toCentreTD">SBK Marks Out of (70)(A)</th>
                  <th className="toCentreTD">Academic Marks Out of (20)(B)</th>
                  <th className="toCentreTD">
                    Professional Marks Out of (10)(C)
                  </th>
                  <th className="toCentreTD">Total Aggregate A+B+C</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="toCentreTD">{data[0].obtained}</td>
                  <td className="toCentreTD">{data[0].acadMarks}</td>
                  <td className="toCentreTD">{data[0].profMarks}</td>
                  <td className="toCentreTD">
                    {data[0].obtained + data[0].acadMarks + data[0].profMarks}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div style={{ textAlign: "left" }}>
            <h4>
              <u>Instructions For Result Card:</u>
            </h4>
            <ol>
              <li>This examination has a maximum score of 100.</li>

              <li>Errors and omissions accepted.</li>

              <li>
              The Candidates are advised to register their queries regarding discrepancy in results (if any), on SBK website through Online Complaint Form within 7 working days. The queries received will be resolved after the query period. Queries received after the deadline would not be entertained.
              </li>
 
              
            </ol>
          </div>
          <div className="printed-date">
            <p>Printed On: {resultDate}</p>
          </div>
        </div>
      </div>
    </>
  );
});
export default ResultToPrint;
