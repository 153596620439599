import React from 'react'
import {Table } from 'react-bootstrap'
import Navbar from '../../Components/Layout/Navbar'
import TopNav from '../../Components/Layout/TopNav'
import { Link } from 'react-router-dom'
import Footer from '../../Components/Layout/Footer'

function Basima() {
  return (
    <>
      <TopNav />
    <Navbar />
    <div className='container'>
        
    <Table  bordered variant="dark">
      <thead>
        <tr style={{backgroundColor:"green",color:"white",textAlign:"center"}}>
          
          <th colSpan={2} style={{textAlign:"center",fontSize:"14pt"}}>Answer Keys Basima Division</th>
          
        </tr>
      </thead>
      <tbody style={{backgroundColor:"#377840",color:"white"}}>
     
      <tr >
          
          <td>JAT</td>
          <td><Link to='../assets/AnswerKeys/Basima/JAT.pdf' target="_blank" rel="noopener noreferrer" ><span style={{color:"white"}}>Click Here</span></Link></td>
          </tr>
          
          <tr >
          
          <td>JET-Tech</td>
          <td><Link to='../assets/AnswerKeys/Basima/JET-TECH.pdf' target="_blank" rel="noopener noreferrer" ><span style={{color:"white"}}>Click Here</span></Link></td>
          </tr>
          
          <tr >
          
          <td>JET</td>
          <td><Link to='../assets/AnswerKeys/Basima/JET.pdf' target="_blank" rel="noopener noreferrer"><span style={{color:"white"}}>Click Here</span></Link></td>
          </tr>
          <tr >
          
          <td>EST</td>
          <td><Link to='../assets/AnswerKeys/Basima/EST.pdf' target="_blank" rel="noopener noreferrer"><span style={{color:"white"}}>Click Here</span></Link></td>
          </tr>

          <tr >
          
          <td>PET</td>
          <td><Link to='../assets/AnswerKeys/Basima/PET.pdf' target="_blank" rel="noopener noreferrer"><span style={{color:"white"}}>Click Here</span></Link></td>
          </tr>
          <tr >
          
          <td>MQ</td>
          <td><Link to='../assets/AnswerKeys/Basima/MQ.pdf' target="_blank" rel="noopener noreferrer"><span style={{color:"white"}}>Click Here</span></Link></td>
          </tr>
          <tr >
          
          <td>JDM</td>
          <td><Link to='../assets/AnswerKeys/Basima/JDM.pdf' target="_blank" rel="noopener noreferrer"><span style={{color:"white"}}>Click Here</span></Link></td>
          </tr>
          <tr >
          
          <td>JVT-I</td>
          <td><Link to='../assets/AnswerKeys/Basima/JVT-I.pdf' target="_blank" rel="noopener noreferrer"><span style={{color:"white"}}>Click Here</span></Link></td>
          </tr>
          <tr >
          
          <td>JVT-II</td>
          <td><Link to='../assets/AnswerKeys/Basima/JVT-II.pdf' target="_blank" rel="noopener noreferrer"><span style={{color:"white"}}>Click Here</span></Link></td>
          </tr>
          
      </tbody>
    </Table>
    </div>
    <Footer />
     </>
  )
}

export default Basima