import React from 'react'
import {Table } from 'react-bootstrap'
import Navbar from '../Components/Layout/Navbar'
import TopNav from '../Components/Layout/TopNav'
import { Link } from 'react-router-dom'
import Footer from '../Components/Layout/Footer'

function Answerkesy() {
  return (
    <>
      <TopNav />
    <Navbar />
    <div className='container'>
        
    <Table  bordered variant="dark">
      <thead>
        <tr style={{backgroundColor:"green",color:"white",textAlign:"center"}}>
          
          <th colSpan={2} style={{textAlign:"center",fontSize:"14pt"}}>Answer Keys</th>
          
        </tr>
      </thead>
      <tbody style={{backgroundColor:"#377840",color:"white"}}>
     
      <tr >
          
          <td><h4 style={{color:"white"}}> Rakhshan Division</h4></td>
          <td><Link to='/Rakhshan' ><span style={{color:"white"}}><h4 style={{color:"white"}}>Click Here</h4></span></Link></td>
          </tr>
          <tr >
          
          <td><h4 style={{color:"white"}}> Zhob Division</h4></td>
          <td><Link to='/Zhob' ><span style={{color:"white"}}><h4 style={{color:"white"}}>Click Here</h4></span></Link></td>
          </tr>
          <tr >
          
          <td><h4 style={{color:"white"}}> Loralai Division</h4></td>
          <td><Link to='/Loralai' ><span style={{color:"white"}}><h4 style={{color:"white"}}>Click Here</h4></span></Link></td>
          </tr>

          <tr >
          
          <td><h4 style={{color:"white"}}> Kalat-I Division</h4></td>
          <td><Link to='/KalatI' ><span style={{color:"white"}}><h4 style={{color:"white"}}>Click Here</h4></span></Link></td>
          </tr>

          <tr >
          
          <td><h4 style={{color:"white"}}> Kalat-II Division</h4></td>
          <td><Link to='/KalatII' ><span style={{color:"white"}}><h4 style={{color:"white"}}>Click Here</h4></span></Link></td>
          </tr>

          <tr >
          
          <td><h4 style={{color:"white"}}> Makran Division</h4></td>
          <td><Link to='/Makran' ><span style={{color:"white"}}><h4 style={{color:"white"}}>Click Here</h4></span></Link></td>
          </tr>

          <tr >
          
          <td><h4 style={{color:"white"}}> Quetta-I Division</h4></td>
          <td><Link to='/QuettaI' ><span style={{color:"white"}}><h4 style={{color:"white"}}>Click Here</h4></span></Link></td>
          </tr>
          <tr >
          
          <td><h4 style={{color:"white"}}> Nasirabad-I Division</h4></td>
          <td><Link to='/NaseerabadI' ><span style={{color:"white"}}><h4 style={{color:"white"}}>Click Here</h4></span></Link></td>
          </tr>
          <tr >
          
          <td><h4 style={{color:"white"}}> Nasirabad-II Division</h4></td>
          <td><Link to='/NaseerabadII' ><span style={{color:"white"}}><h4 style={{color:"white"}}>Click Here</h4></span></Link></td>
          </tr>
          <tr >
          
          <td><h4 style={{color:"white"}}> Sibi Division</h4></td>
          <td><Link to='/Sibi' ><span style={{color:"white"}}><h4 style={{color:"white"}}>Click Here</h4></span></Link></td>
          </tr>
          <tr >
          
          <td><h4 style={{color:"white"}}>Quetta-II</h4></td>
          <td><Link to='/QuettaII' ><span style={{color:"white"}}><h4 style={{color:"white"}}>Click Here</h4></span></Link></td>
          </tr>
          <tr >
          
          <td><h4 style={{color:"white"}}>Basima Center</h4></td>
          <td><Link to='/Basima' ><span style={{color:"white"}}><h4 style={{color:"white"}}>Click Here</h4></span></Link></td>
          </tr>
      </tbody>
    </Table>
    </div>
    <Footer />
     </>
  )
}

export default Answerkesy