import React from 'react'
import {Table } from 'react-bootstrap'
import Navbar from '../Components/Layout/Navbar'
import TopNav from '../Components/Layout/TopNav'
import { Link } from 'react-router-dom'
import Footer from '../Components/Layout/Footer'
import Adsense from '../Components/Layout/Adsense'
function ProjectDetail() {
  return (
    <>
    <TopNav />
    <Navbar />
    <div className='container'>
        
    <Table  bordered variant="dark">
      <thead>
        <tr style={{backgroundColor:"green",color:"white",textAlign:"center"}}>
          
          <th colSpan={2} style={{textAlign:"center",fontSize:"14pt"}}>Schools Education Department Balochistan Recruitment Project 2023 (Teaching-BPS-09 To BPS-15)</th>
          
        </tr>
      </thead>
      <tbody style={{backgroundColor:"#377840",color:"white"}}>
      <tr>  
          <td>To Download Advertisement</td>
          <td><a href="assets/vacancy/Advt.pdf" target="_blank" style={{color:"white"}}>Click Here</a></td>
        
        </tr>
        <tr>  
          <td>Corrigendum</td>
          <td><a href="assets/PressRelease/Corrigendum1.pdf" target="_blank" style={{color:"white"}}>Click Here</a></td>
        
        </tr>
     
        <tr>

           <td>Press Release</td>
           <td><a href="assets/PressRelease/PressRelease1.pdf" target="_blank"><span style={{color:"white"}}>Click Here</span></a></td>

        </tr>
        <tr>

           <td>Press Release (Fee Challan)</td>
           <td><a href="assets/PressRelease/Pressrelease2.pdf" target="_blank"><span style={{color:"white"}}>Click Here</span></a></td>

        </tr>
        <tr>

          <td>How To Apply Application Guidelines</td>
          <td><a href="https://youtu.be/c1l_psFXUF0" target="_blank"><span style={{color:"white"}}>Click Here</span></a></td>
       
        </tr>
        <tr style={{backgroundColor:""}}>

         
          <td >Online Application Form</td>
          <td><a href="https://registration.edurecruit.com.pk/" target="_blank"><span style={{color:"white"}}>Click Here</span></a></td>
       
        </tr>
        <tr>
          
          <td>Last Date</td>
          <td>22<sup>th</sup>March,2023</td>
        </tr>
        <tr >
          
          <td>Syllabus</td>
          <td><a href="assets/PressRelease/Syllabus.pdf" target="_blank"><span style={{color:"white"}}>Click Here</span></a></td>
        </tr>
        <tr>
          
          <td>Syllabus Exam Schedule and Paper Pattern Tutorials</td>
          <td><a href="https://youtu.be/IgO4nUQr0JQ" target="_blank"><span style={{color:"white"}}>Click Here</span></a></td>
        </tr>
        <tr >
          
          <td>Accepted & Rejected Candidates List</td>
          <td><Link to='/Listing' ><span style={{color:"white"}}>Click Here</span></Link></td>
          </tr>
                  
          <tr>
          
          <td>Accept & Reject Complaint Last date</td>
          <td><span style={{color:"white"}}>27th April 2023</span></td>
          </tr>
          <tr>
          
          <td>How To register Online complaint Guideline</td>
          <td><a href="https://www.youtube.com/watch?v=qvaQ5OnXv5I" target="_blank"><span style={{color:"white"}}>Click Here</span></a></td>
        
          </tr>
          
          <tr style={{backgroundColor:"#00c69e"}}>
          
          <td style={{color:"yellow",fontWeight:"bold"}}>
          <img src="ClickHere.gif" style={{width:"10%",height:"30px"}} alt="alt" />
            Tentative Merit List</td>
          <td><h4><Link to='/MeritList'><span style={{color:"Red"}}>Click Here</span></Link></h4></td>
        
          </tr>



      </tbody>
    </Table>
    </div>
    <Footer />
    </>
    
  )
}

export default ProjectDetail